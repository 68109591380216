﻿@use "sass:math";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $colorDark;
    font-weight: 400;
    font-family: Circe;
    font-style: normal;
    line-height: 1;
    letter-spacing: unset;
    text-align: left;
    text-decoration: none;
    list-style-type: none;
    background: none;
    border: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    appearance: none;
    -webkit-font-smoothing: antialiased;
}

@mixin bg($url, $size, $pos) {
    background: url($url) no-repeat;
    background-position: $pos;
    background-size: $size;
}

@mixin mediaMin($w) {
    @media screen and (min-width: $w) {
        @content;
    }
}

@mixin media($w1, $w2) {
    @media screen and (min-width: $w1) and (max-width: $w2) {
        @content;
    }
}

@mixin desktop() {
    @include mediaMin($mediaM + 1px) {
        @content;
    }
}

@mixin desktop-l() {
    @include mediaMin(1920px) {
        @content;
    }
}

@mixin mobile() {
    @include media(0, $mediaM) {
        @content;
    }
}

html {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    font-size: calc(100vw / 1512);

    @include desktop-l {
        font-size: 1.3px;
    }

    @include mobile {
        font-size: 1px;
    }
}

@mixin icon($color, $colorFill: null, $colorStroke: null, $colorAll: null) {
    @if $colorFill {
        & .icon__fill {
            fill: $colorFill;
        }
    }

    @if $colorStroke {
        & .icon__stroke {
            stroke: $colorStroke;
        }
    }

    @if $colorAll {
        & .icon__all {
            fill: $colorAll;
            stroke: $colorAll;
        }
    }

    @if $colorFill==null and $colorStroke==null and $colorAll==null {
        & .icon__fill {
            fill: $color;
        }

        & .icon__stroke {
            stroke: $color;
        }
    }
}

@mixin inParent($parent, $class) {
    .#{$parent}.#{$class} & {
        @content;
    }
}

@mixin blur($val) {
    & {
        backdrop-filter: saturate(180%) blur($val);

        //
        -webkit-backdrop-filter: saturate(180%) blur($val);
    }
}

@mixin button($value: 0.96, $centerY: false) {
    will-change: transform;

    & {
        transition: $trans;
    }

    &:active {
        @if $centerY {
            transform: translate(0, -50%) scale(#{$value});
        }

 @else {
            transform: scale(#{$value});
        }
    }
}

._SECTION {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &._PUBLIC {
        min-height: 100%;
        padding: 70rem 0 50rem;

        @include mobile {
            padding: 116rem 0 32rem;
        }
    }
}

._INNER {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1200rem;

    @include mobile {
        width: calc(100% - 40rem);
        margin-right: auto;
        margin-left: auto;
    }
}

._FULL-INNER {
    width: 100%;
    padding: 0 62rem;

    @include mobile {
        padding: 0 20rem;
    }
}

._ROW {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

._COL {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

._CLICK {
    @include mediaMin($mediaM + 1px) {
        cursor: pointer;
    }

    user-select: none;
}

label {
    @extend ._CLICK;
}

._NOSCROLL {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }
}

._LOADER {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: $trans;
    pointer-events: none;

    &._SCROLL {
        top: auto;
        bottom: 0;
        height: 30%;
        min-height: 150rem;
        padding-top: 64rem;
        background: linear-gradient(to top, #fff, rgba(#fff, 0));

        &._grey {
            background: linear-gradient(to top, #f3f7f9, rgba(#f3f7f9, 0));
        }

        &._reverse {
            top: auto;
            bottom: auto;
            padding-top: 0;
            padding-bottom: 64rem;
            background: linear-gradient(to bottom, #fff, rgba(#fff, 0));
        }

        &._grey._reverse {
            background: linear-gradient(to bottom, #f3f7f9, rgba(#f3f7f9, 0));
        }
    }

    &:not(._show) {
        opacity: 0;
    }
}

._POPUPBACK {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 100%;
    background: rgba(#d9e0e2, 0.2);

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: rgba(#fff, 0.32);
        content: "";
    }

    @include blur(54rem);
}

._LOADERITEM {
    width: 30rem;
    height: 30rem;
    margin: auto;

    @include mobile {
        width: 26rem;
        height: 26rem;
    }
}

@mixin scrollLoader($color) {
    & ._LOADER._SCROLL {
        background: $color;
    }
}

@mixin loader($color) {
    & .loader__itemInner {
        background: $color;
    }

    & .loader__icon {
        @include icon($color);
    }
}

._TITLE {
    color: $colorDark;
    font-weight: 600;
    font-size: 48rem;
    line-height: 1.2;
}

._TEXT {
    color: $colorDark;
    font-size: 20rem;
    font-family: Arial;
    line-height: 1.4;
}

._ITEMS {
    position: relative;
    width: 100%;
    transition: $trans;
}

._ITEM {
    position: absolute;
    top: 0;
    left: 0;
    transition: $trans;

    &:not(._show) {
        opacity: 0;
    }
}

._parentForce ._ITEM,
._ITEMS._static ._ITEM {
    position: relative;

    &:not(._show) {
        display: none;
    }
}

._EDITORBLOCK {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 2px solid transparent;

    &._edit > div {
        width: 100%;
        min-height: 40rem;
    }

    &._edit {
        border-color: #2caaff;
    }
}

._ANIMATE {
    transition: $trans;

    &:not(._show) {
        opacity: 0;
    }
}

@mixin editor() {
    &,
    & * {
        @content;
    }
}

._NOWRAP {
    white-space: nowrap;
}

._WRAP {
    word-break: break-all;
}

._INFOBLOCKWRAPPER {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

._LOGO {
    width: 170rem;

    @include mobile {
        display: none;
    }
}

._EDITORCONTENT {
    color: $colorDark;

    & * {
        font-weight: 300;
        line-height: 1.4;
    }

    & b {
        font-weight: 500;

        & * {
            font-weight: 500;
        }
    }

    & i {
        font-style: italic;

        & * {
            font-style: italic;
        }
    }

    & ._alignLeft {
        text-align: left;
    }

    & ._alignCenter {
        text-align: center;
    }

    & ._alignRight {
        text-align: right;
    }

    & ._alignJustify {
        text-align: justify;
    }

    & ._underline {
        text-decoration: underline;
    }

    & ._uppercase {
        text-transform: uppercase;
    }

    & ._quote {
        padding: 24rem;
        background: rgba($colorMain, 0.12);
        border-radius: 18rem;
    }

    & ._ancor {
        cursor: pointer;
    }

    & ._indent {
        padding-left: 20rem;
    }

    & p {
        line-height: 1.4;
    }

    & p + p {
        // margin-top: 20px;
    }

    & ol,
    & ul {
        padding-left: 0;
        counter-reset: listCounter;
    }

    & ._listPoint {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1.4;
    }

    & li {
        position: relative;
        padding-left: 26rem;
        line-height: 1.4;
        counter-increment: listCounter;

        &._remove::before {
            content: none !important;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            line-height: 1.4;
            content: "•";
        }

        & > ul,
        & > ol {
            margin-top: 12rem;
        }
    }

    & ol > li::before {
        content: counter(listCounter) ".";
    }

    & li + li {
        margin-top: 12rem;
    }

    & img {
        position: relative;
        z-index: 1;
        display: block;
        max-width: 100%;
        overflow: hidden;
        border: 1px solid $colorMain;
        border-radius: 20rem;
    }

    & table {
        width: 100%;
        border: 1rem solid rgba($colorDark, 0.24);
        border-spacing: 0;

        & tr {
            display: table-row;
        }

        & td {
            display: table-cell;
            padding: 10rem;
            vertical-align: top;

            &:empty {
                height: 40rem;
            }

            &:not(:last-child) {
                border-right: 1rem solid rgba($colorDark, 0.24);
            }
        }

        & tr:not(:last-child) td {
            border-bottom: 1rem solid rgba($colorDark, 0.24);
        }
    }

    & a {
        display: inline-block;
        color: $colorMain;
        font-weight: 500;
        line-height: 0.9;
        border-bottom: 1rem solid rgba($colorMain, 0.5);
    }
}

._FOCUS {
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        box-shadow: 0 0 0 4rem rgba($colorMain, 0.24);
        opacity: 0;
        transition: $trans;
        content: "";
        pointer-events: none;
    }

    &:focus-visible::before {
        opacity: 1;
    }
}

._FOCUS-INSET {
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        box-shadow: inset 0 0 0 2rem rgba($colorMain, 0.5);
        opacity: 0;
        transition: $trans;
        content: "";
        pointer-events: none;
    }

    &:focus-visible::before {
        opacity: 1;
    }
}

._FOCUS-CENTER::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin hover() {
    @media (hover: hover) {
        @content;
    }
}

._DESKTOP {
    @include mobile {
        display: none;
    }
}

._MOBILE {
    @include mediaMin($mediaM + 1px) {
        display: none;
    }
}

._MOBMAX {
    @include mobile {
        max-width: 320rem;
        margin-right: auto !important;
        margin-left: auto !important;
    }
}
